<template>
	<main>
		<h1>Affiliates</h1>

		<ResponsiveTable :headers="['Name', 'Domain', 'Courses', '']" :rows="affiliates" />

		<BaseButton type="primary" :href="{name: 'AdminAffiliateAdd'}">
			Add affiliate
		</BaseButton>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton';
	import ResponsiveTable from '../../components/ResponsiveTable';
	import {roleGuard} from '../../inc/auth';

	export default {
		...roleGuard('admin'),
		components: {
			BaseButton,
			ResponsiveTable
		},
		data() {
			return {
				affiliates: []
			};
		},
		created() {
			Backend.get('/affiliates').then(res => {
				this.affiliates = res.data.map(affiliate => [
					{label: affiliate.name},
					{label: affiliate.domain},
					{label: affiliate.courses.length},
					{label: 'Edit', href: {name: 'AdminAffiliateEdit', params: {id: affiliate.id}}}
				]);
			});
		}
	};
</script>